<template>
  <div v-if="!isLoading" class="project">
    <Back to="/projects">Назад</Back>

    <DashboardHeader
      :title="project.name"
      caption="Дашборд: проект"
      :updated-at="project.updated_at"
      :disable-check="!selectedEirs.length || changing"
      :selected-eirs.sync="selectedEirs"
      :progress="overallProgress"
    >
      <template #actions>
        <DashboardHeaderInfo
          :code="project.project_code"
          :address="project.construction_address"
          :users="project.allowed_users"
        />
        <v-btn
          text
          icon
          small
          title="Скачать отчет для Excel, .xlsx"
          color="primary"
          class="ml-1"
          @click="exportToXLSX"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="isManager || isAdmin"
          text
          icon
          small
          title="Изменить"
          color="primary"
          class="ml-1"
          @click="$refs.modalProject.show(project, 'update', false)"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="isManager || isAdmin"
          text
          icon
          small
          title="Настройки проекта"
          color="primary"
          class="ml-1"
          :to="`${projectId}/settings`"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          small
          title="Пояснительная записка"
          color="primary"
          class="ml-1"
          :to="`${projectId}/pz`"
        >
          <v-icon>mdi-note-text-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="isManager || isAdmin"
          text
          icon
          small
          title="Удалить"
          color="primary"
          class="ml-1"
          @click="removeProject"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </DashboardHeader>
    <v-divider class="my-3" />

    <div class="d-flex flex-column flex-xl-row">
      <div class="pr-xl-4 mb-4 mb-xl-0 flex-grow-1">
        <v-card outlined height="100%">
          <DashboardEirsBlock
            :elements="eirElements"
            :filters="eirFilters"
            :sets="project.eir_sets"
            :sets-ids="eirSetsIds"
            :project-id="projectId"
            :fetch-data="fetchEirElements"
            :total="totalEirElements"
            :selected.sync="selectedEirs"
            :status-getter="getCheckStatusById"
            :elements-getter="getElementsById"
            :elements-count="revitElementsCount"
            :progress="progress"
          />
        </v-card>
      </div>
    </div>

    <ModalProject ref="modalProject" @afterSubmit="save" />
  </div>
  <v-progress-linear v-else class="loader" color="primary" indeterminate />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import * as XLSX from 'xlsx/xlsx.mjs'

import Back from '@/components/Back'
import ModalProject from '@/components/ModalProject'

import DashboardHeader from '@/views/ProjectItem/partials/DashboardHeader'
import DashboardHeaderInfo from '@/views/ProjectItem/partials/DashboardHeaderInfo'
import DashboardEirsBlock from '@/views/ProjectItem/partials/DashboardEirsBlock'

export default {
  name: 'ProjectItem',
  components: {
    DashboardHeaderInfo,
    DashboardEirsBlock,
    Back,
    ModalProject,
    DashboardHeader,
  },
  data: () => ({
    isLoading: false,
    eirSetsIds: '',
    selectedEirs: [],
  }),
  computed: {
    ...mapState('projects', ['project']),
    ...mapState('eirElements', [
      'eirElements',
      'totalEirElements',
      'eirFilters',
    ]),
    ...mapState('parameters', [
      'parameters',
      'totalParameters',
      'paramFilters',
      'saprFields',
    ]),
    ...mapGetters(['isAdmin', 'isManager']),
    ...mapState('revit', [
      'syncStatus',
      'changeDocumentStatus',
      'revitElementsCount',
      'revitParametersCount',
      'progress',
      'activeDocument',
    ]),
    ...mapGetters('revit', ['getCheckStatusById', 'getElementsById']),
    projectId: (vm) => vm.$route.params.projectId,
    changing: (vm) => vm.changeDocumentStatus === 2,
    overallProgress: (vm) => {
      const keys = Object.keys(vm.progress)
      if (!keys?.length) return null

      const total = vm.totalEirElements
      const checked = keys.reduce((acc, key) => {
        const elementsLength = vm.getElementsById(key)?.length
        /*
         * Нет элементов, подходящих под фильтр, поэтому считаем, что требованию соответствует
         * */
        if (elementsLength === 0) return acc + 100

        return acc + vm.progress[key]
      }, 0)
      const progress = Math.floor(checked / total)

      return isNaN(progress) ? null : progress
    },
  },
  created() {
    this.fetchSaprFields()
  },
  async mounted() {
    this.isLoading = true

    this.resetElementsState()
    this.resetParametersState()

    await this.fetchProject(this.projectId)

    const eirSets = this.project.eir_sets

    if (eirSets.length) {
      this.eirSetsIds = eirSets?.map(({ id }) => id).join(',')

      await this.fetchSections({ eir_set__in: this.eirSetsIds })
    }

    await this.syncWithRevit()

    this.isLoading = false
  },
  methods: {
    ...mapActions('projects', ['fetchProject', 'deleteProject']),
    ...mapActions('sections', ['fetchSections']),

    ...mapMutations('eirElements', ['resetElementsState']),
    ...mapActions('eirElements', ['fetchEirElements']),

    ...mapMutations('parameters', ['resetParametersState']),
    ...mapActions('parameters', ['fetchParams', 'fetchSaprFields']),

    ...mapActions('revit', ['syncWithRevit']),
    async save(callback) {
      await this.fetchProject(this.projectId)
      callback()
    },
    async removeProject() {
      if (!confirm('Удалить проект?')) return

      this.isLoading = true

      await this.deleteProject(this.projectId)
      await this.$logActionEvent('Проект удалён')

      this.isLoading = false

      await this.$router.push('/')
    },
    exportToXLSX() {
      const elements = this.eirElements.map((e) => {
        const count = this.revitElementsCount[e.id]
        const elsLength = this.getElementsById(e.id)?.length

        return [
          e.name?.replaceAll(',', ';'),
          `${count?.countMatch ?? '—'} из ${elsLength ?? '—'}`,
          `${count?.countPresent ?? '—'} из ${e.parameters_count ?? 0}`,
          elsLength === 0 ? 100 : this.progress[e.id] ?? '—',
        ]
      })
      const rows = [
        [
          'Название файла, выбранного в момент составления отчета',
          'Название проекта',
          'Общее соответствие %',
        ],
        [
          this.activeDocument.title,
          this.project.name?.replaceAll(',', ';'),
          '',
          this.overallProgress,
        ],
        [
          'Название требования',
          'Кол-во элементов САПРА (соотв. требованию / подходящие под фильтр)',
          'Кол-во параметров (на элементах САПРа / все)',
          'Соответствие %',
        ],
        ...elements,
      ]
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.aoa_to_sheet(rows)

      worksheet['!cols'] = [
        { wpx: 400 },
        { wpx: 300 },
        { wpx: 300 },
        { wpx: 100 },
      ]
      worksheet['!rows'] = [{ hpx: 30 }, {}, { hpx: 40 }]

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Общий отчет')
      XLSX.writeFile(workbook, 'irma-overall-report.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
