<template>
  <v-autocomplete
    v-model="selectedFileModel"
    prepend-inner-icon="mdi-file-document-outline"
    return-object
    item-text="title"
    item-value="title"
    outlined
    dense
    hint="При смене файла результаты проверок будут очищены!"
    persistent-hint
    label="Выберите файл"
    :items="openedDocuments"
    :disabled="!connected || changing"
    :loading="changing"
  />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ChangeFileBlock',
  computed: {
    ...mapState('revit', [
      'syncStatus',
      'activeDocument',
      'openedDocuments',
      'changeDocumentStatus',
    ]),
    connected: (vm) => vm.syncStatus === 3,
    changing: (vm) => vm.changeDocumentStatus === 2,
    selectedFileModel: {
      get() {
        return this.activeDocument
      },
      set(file) {
        if (file?.title === this.activeDocument?.title) return

        this.changeActiveDocument(file)
      },
    },
  },
  watch: {
    activeDocument(newVal, oldVal) {
      if (newVal?.title !== oldVal?.title) this.resetStateOfChecks()
    },
  },
  methods: {
    ...mapMutations('revit', ['resetStateOfChecks']),
    ...mapActions('revit', ['changeActiveDocument']),
  },
}
</script>
