<template>
  <v-menu
    v-model="showInfo"
    class="elevation-0"
    open-on-hover
    transition="slide-y-transition"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        icon
        small
        title="Описание и участники"
        color="primary"
        class="ml-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card style="max-width: 300px">
      <template v-if="code || address">
        <v-card-title class="pb-2">Описание</v-card-title>
        <div class="px-4 pb-3">
          <div v-if="code" class="mb-2">
            <span class="font-weight-bold body-2">Шифр: </span>
            {{ code }}
          </div>
          <div v-if="address">
            <span class="font-weight-bold body-2">Адрес: </span>
            {{ address }}
          </div>
        </div>
      </template>

      <template v-if="users?.length">
        <v-card-title class="pb-0">Участники</v-card-title>
        <v-list dense>
          <template v-for="(user, index) in users">
            <v-divider v-if="index % 2 !== 0" :key="index" inset />
            <v-list-item :key="`user-${user.email}`">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getName(user) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="checkIfHasName(user)">
                  {{ user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'DashboardHeaderInfo',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    code: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showInfo: false,
  }),
  methods: {
    getName(user) {
      if (!this.checkIfHasName(user)) return user.email

      const lastName = user.last_name
      const name = user.first_name

      return name ? `${name} ${lastName}` : lastName
    },
    checkIfHasName(user) {
      return user.first_name || user.last_name
    },
  },
}
</script>
