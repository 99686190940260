<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    width="476"
    persistent
    @keydown.esc="dialog = false"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">
            {{
              formMode === 'create'
                ? 'Добавление проекта'
                : 'Редактирование проекта'
            }}
            <v-spacer />
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-3">
            <v-text-field
              v-model.trim="fields.name"
              label="Наименование проекта*"
              :rules="[rules.required]"
              outlined
              dense
            />

            <v-text-field
              v-model.trim="fields.project_code"
              label="Шифр*"
              :rules="[rules.required]"
              outlined
              dense
            />

            <v-text-field
              v-model.trim="fields.construction_address"
              label="Адрес строительства*"
              :rules="[rules.required]"
              outlined
              dense
            />

            <div class="mt-6">*&nbsp;&mdash; обязательно</div>
          </v-card-text>

          <SaveCloseButtons
            :form-mode="formMode"
            :disabled="isLoading"
            @close="dialog = false"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import rules from '@/mixins/rules'
import SaveCloseButtons from './SaveCloseButtons'
import { mapActions } from 'vuex'

export default {
  name: 'ModalProject',
  components: { SaveCloseButtons },
  mixins: [rules],
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    formMode: 'create',
    isFetchProjects: true,
    fields: {
      name: '',
      project_code: '',
      construction_address: '',
      access_type: 'private',
    },
  }),
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    ...mapActions('projects', [
      'fetchProjects',
      'createProject',
      'updateProject',
    ]),
    show(data = {}, formMode = 'create', isFetchProjects = true) {
      this.formMode = formMode
      if (formMode === 'update') {
        this.fields = { ...data }
      }
      this.isFetchProjects = isFetchProjects
      this.dialog = true
    },
    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true

        if (this.formMode === 'create') {
          await this.createProject(this.fields)
          await this.$logActionEvent('Добавлен новый проект')
        } else {
          await this.updateProject(this.fields)
          await this.$logActionEvent('Изменён проект')
        }

        if (this.isFetchProjects) {
          await this.fetchProjects()
        }

        if (this._events.afterSubmit) {
          this.$emit('afterSubmit', this.afterSubmit)
        } else {
          this.afterSubmit()
        }
      }
    },
    afterSubmit() {
      this.isLoading = false
      this.dialog = false
    },
  },
}
</script>
