<template>
  <v-data-table
    v-model="selectedModel"
    show-select
    group-by="eir_set"
    :loading="isLoading"
    :headers="headers"
    :items="elements"
    :items-per-page="20"
    :hide-default-footer="total <= 20"
    :server-items-length="total"
    :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
    :options.sync="tableOptions"
    :search="search"
    :mobile-breakpoint="0"
    disable-sort
  >
    <template #top>
      <h3 class="ma-2">Требования</h3>
      <div class="d-flex ma-2 pt-1">
        <div class="flex-grow-1 mr-2">
          <v-text-field
            v-model="search"
            label="Поиск по наименованию"
            outlined
            hide-details
            dense
            clearable
          />
        </div>
        <div class="flex-grow-1" style="max-width: 50%">
          <SelectSections
            option-key="section__in"
            option-key-default="eir_set__in"
            :data-default="setsIds"
            persistent-state
            @input="(filters) => prepareSections(filters)"
          />
        </div>
      </div>
    </template>

    <template #header.elements_count="{ header: { text } }">
      <div class="mb-2">
        <div class="mb-1 body-2 font-weight-bold">{{ text }}</div>
        <div class="text--black">
          (соотв. требованию&nbsp;/<br />подходящие под&nbsp;фильтр)
        </div>
      </div>
    </template>

    <template #header.parameters_count="{ header: { text } }">
      <div class="mb-2">
        <div class="mb-1 body-2 font-weight-bold">{{ text }}</div>
        <div class="text--black">(на&nbsp;элементах САПРа&nbsp;/ все)</div>
      </div>
    </template>

    <template #group.header="{ toggle, isOpen, group }">
      <td :colspan="headers.length - 1" class="text-start">
        <h4 :title="getEirSetName(group)">
          <v-btn icon small rounded @click="toggle">
            <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
          </v-btn>
          <router-link :to="getEirSetLink(group)" target="_blank">
            {{ getEirSetName(group) | truncate(80) }}
          </router-link>
        </h4>
      </td>
      <td colspan="2" class="text-right">
        <v-chip color="primary" small label class="mr-2">
          Изменен: {{ getEirSetUpdatedAt(group) | formatDate }}
        </v-chip>
        <v-chip
          v-if="getEirSetVersion(group)"
          :link="getEirSetVersionsLength(group) > 1"
          :to="
            getEirSetVersionsLength(group) > 1
              ? `/eir-sets/${group}/versions/${getEirSetVersion(group).id}`
              : ''
          "
          color="primary"
          target="_blank"
          small
          label
        >
          Версия: {{ getEirSetVersion(group).version }}
        </v-chip>
      </td>
    </template>

    <template #item.name="{ item }">
      <router-link
        :title="item.name"
        :to="`/projects/${projectId}/eirs/${item.id}/`"
      >
        {{ item.name | truncate(65) }}
      </router-link>
    </template>

    <template #item.elements_count="{ item }">
      <div>
        {{ elementsCount[item.id]?.countMatch ?? '—' }} /
        {{ elementsGetter(item.id)?.length ?? '—' }}
      </div>
    </template>

    <template #item.parameters_count="{ item }">
      <div>
        {{ elementsCount[item.id]?.countPresent ?? '—' }} /
        {{ item.parameters_count ?? 0 }}
      </div>
    </template>

    <template #item.sync="{ item }">
      <v-progress-circular
        v-if="statusGetter(item.id) === 2"
        size="20"
        :width="3"
        color="primary"
        indeterminate
      />
      <v-icon v-else :color="getStatusColor(item.id)">
        {{ getStatusIcon(item.id) }}
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

import SelectSections from '@/components/SelectSections'

import { EIR_TABLE_HEADERS } from '@/views/ProjectItem/constants'

export default {
  name: 'DashboardEirsBlock',
  components: { SelectSections },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    total: {
      type: Number,
      default: null,
    },
    projectId: {
      type: [String, Number],
      default: null,
    },
    sets: {
      type: Array,
      default: () => [],
    },
    setsIds: {
      type: String,
      default: '',
    },
    fetchData: {
      type: Function,
      default: () => () => ({}),
    },
    selected: {
      type: Array,
      default: () => [],
    },
    statusGetter: {
      type: Function,
      default: () => () => 1,
    },
    elementsGetter: {
      type: Function,
      default: () => () => [],
    },
    elementsCount: {
      type: Object,
      default: () => ({}),
    },
    progress: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    headers: EIR_TABLE_HEADERS,
    search: '',
    showEirsInNeed: {},
    tableOptions: {},
    isLoading: false,
  }),
  computed: {
    selectedModel: {
      get() {
        return this.selected
      },
      set(array = []) {
        if (isEqual(array, this.selected)) return

        this.$emit('update:selected', array)
      },
    },
  },
  watch: {
    tableOptions() {
      this.debounceFetch()
    },
    search() {
      if (this.tableOptions.page !== 1) {
        this.tableOptions.page = 1
      } else {
        this.debounceFetch()
      }
    },
  },
  methods: {
    debounceFetch: debounce(function () {
      this.fetchAll()
    }, 400),
    prepareSections(filterSections = {}) {
      const currentPage = this.tableOptions.page
      this.tableOptions.page = 1
      if (!filterSections.section__in) {
        delete this.filters.section__in
      } else {
        this.filters.section__in = filterSections.section__in
      }
      if (currentPage === 1) {
        this.fetchAll()
      }
    },
    async fetchAll() {
      if (!this.setsIds) return

      this.isLoading = true

      await this.fetchData({
        options: this.tableOptions,
        filters: {
          ...this.filters,
          search: this.search,
          eir_set__in: this.setsIds,
        },
      })

      this.isLoading = false
    },
    getEirSetName(group) {
      return (
        this.sets?.find(({ id }) => id === group)?.name ??
        'Набор информационных требований'
      )
    },
    getEirSetLink(group) {
      return { name: 'Набор требований', params: { setId: group } }
    },
    getEirSetVersion(group) {
      return this.sets?.find(({ id }) => id === group)?.version?.[0]
    },
    getEirSetVersionsLength(group) {
      return this.sets?.find(({ id }) => id === group)?.version?.length
    },
    getEirSetUpdatedAt(group) {
      return this.sets?.find(({ id }) => id === group)?.updated_at ?? null
    },
    getStatusIcon(eirId) {
      const status = this.statusGetter(eirId)
      /*
       * Нет элементов, подходящих под фильтр, поэтому считаем, что требованию соответствует
       * */
      const elementsLength = this.elementsGetter(eirId)?.length

      if (!status) return 'mdi-decagram-outline'
      if (elementsLength === 0) return 'mdi-check-decagram-outline'

      return status === 3 && this.progress[eirId] === 100
        ? 'mdi-check-decagram-outline'
        : 'mdi-alert-decagram-outline'
    },
    getStatusColor(eirId) {
      const status = this.statusGetter(eirId)
      const elementsLength = this.elementsGetter(eirId)?.length

      if (!status) return 'warning'
      if (elementsLength === 0) return 'primary'

      return status === 3 && this.progress[eirId] === 100 ? 'primary' : 'error'
    },
  },
}
</script>
