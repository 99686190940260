<template>
  <header>
    <div class="mb-2">
      <v-progress-linear
        :buffer-value="checking ? 90 : 100"
        :value="progressValue"
        height="20"
        stream
        striped
        color="primary"
      >
        <span class="body-2 font-weight-black">
          {{ `Соответствует на ${progressValue ?? '—'}%` }}
        </span>
      </v-progress-linear>
    </div>

    <div class="d-flex flex-column flex-lg-row mb-2">
      <div
        class="mr-2 flex-grow-1"
        :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 50%;' : ''"
      >
        <div class="d-flex align-center mb-1 pr-2">
          <h1>{{ title }}</h1>
          <slot name="actions" />
        </div>

        <div class="d-flex flex-row align-center">
          <span class="caption mr-1">{{ caption }}</span>
          <v-chip small label outlined color="primary" class="mr-1">
            <span class="font-weight-bold">Изменен:&nbsp;</span>
            {{ updatedAt | formatDate }}
          </v-chip>
        </div>
      </div>

      <div
        class="d-flex mt-2 flex-grow-1 align-top"
        :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 50%;' : ''"
      >
        <div class="pr-2 flex-grow-1 flex-shrink-1">
          <ChangeFileBlock />
        </div>
        <div class="flex-grow-1 flex-shrink-1 pr-2">
          <v-btn
            color="primary"
            elevation="10"
            :disabled="!connected || !activeDocument || disableCheck"
            :loading="checking"
            width="100%"
            height="40"
            @click="check"
          >
            <v-icon class="mr-2">mdi-clipboard-check-multiple-outline</v-icon>
            Проверить на соответствие
          </v-btn>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import cloneDeep from 'lodash/cloneDeep'

import ChangeFileBlock from '@/components/ChangeFileBlock'

export default {
  name: 'DashboardHeader',
  components: { ChangeFileBlock },
  props: {
    title: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
    disableCheck: {
      type: Boolean,
      default: false,
    },
    selectedEirs: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    checking: false,
  }),
  computed: {
    ...mapState('revit', ['syncStatus', 'activeDocument']),
    connected: (vm) => vm.syncStatus === 3,
    progressValue: (vm) => (vm.activeDocument ? vm.progress : null),
  },
  methods: {
    ...mapActions('revit', ['checkRevitProject']),
    ...mapActions('parameters', ['fetchParams']),
    ...mapMutations('revit', ['switchCheckStatus']),
    async check() {
      this.checking = true

      for await (const eirElement of this.selectedEirs) {
        await this.checkEir(eirElement)
      }

      this.checking = false
    },
    async checkEir(eirElement) {
      /*
       * Чтобы лоадер крутился и при загрузке параметров
       * */
      this.switchCheckStatus({ eirElementId: eirElement.id, status: 2 })

      const conditions = eirElement.revit_filter?.conditions ?? []
      const parameters =
        (await this.fetchParams({
          filters: { eir: eirElement.id },
          onlyValue: true,
        })) ?? []

      try {
        await this.checkRevitProject({
          projectId: +(this.$route.params.projectId ?? 0),
          eirElementId: eirElement.id,
          filter: {
            conditions,
            parameters,
          },
          stopSwitch: true,
        })

        this.switchCheckStatus({ eirElementId: eirElement.id, status: 3 })
        this.removeSelectedEir(eirElement.id)
      } catch (e) {
        this.$logger('error', '[revit/checkRevitProjectInSequence]', e)
      }
    },
    removeSelectedEir(eirElementId) {
      let selected = cloneDeep(this.selectedEirs)

      const index = selected.findIndex((item) => item.id === eirElementId)
      if (index === -1) return

      selected.splice(index, 1)
      this.$emit('update:selectedEirs', selected)
    },
  },
}
</script>
