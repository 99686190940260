export const EIR_TABLE_HEADERS = [
  { text: 'Наименование', value: 'name' },
  { text: 'Кол-во элементов САПРа', value: 'elements_count' },
  { text: 'Кол-во параметров', value: 'parameters_count' },
  { text: 'Соответствие требованию', value: 'sync' },
]

export const PARAMETERS_TABLE_HEADERS = [
  { text: 'Наименование', value: 'name' },
  {
    text: 'Кол-во требований c параметром',
    value: 'eirs_count',
    style: 'width: 93px;',
  },
  {
    text: 'Кол-во параметров на элементах САПРа',
    value: 'parameters_count',
  },
  {
    text: 'Обязателен',
    value: 'is_required',
  },
  {
    text: 'Общий',
    value: 'REVIT_IS_SHARED',
  },
  {
    text: 'Код параметра (системный)',
    value: 'REVIT_IS_SYSTEM',
  },
]
